<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="Name" label-for="name" class="mb-0">
              <b-form-input
                id="name"
                v-model="search.name"
                class="form-control"
                placeholder="Please Enter Name"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Sport Type Id" label-for="name" class="mb-0">
              <v-select
                v-model="search.sport_type_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sportTypeId"
                :reduce="(val) => val.id"
                :clearable="false"
                placeholder="Select category"
                label="name"
                input-id="category"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-end justify-content-end"
          >
            <b-button variant="outline-primary" @click="resetFilter()">
              Reset
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              Search
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" v-if="$can('create', 'sportteam')">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="showAddSportTeamModal()">
                <span class="text-nowrap">
                  <feather-icon icon="PlusIcon" /> New
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refSportTeamListTable"
        class="position-relative table-white-space mb-0 max-height-table"
        sticky-header
        head-variant="light"
        :items="sportTeamLists"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <b-link
            class="cursor-pointer text-primary"
            @click="showEditSportTeamModal(data.item)"
          >
            {{ data.item.name }}
          </b-link>
        </template>
        <template #cell(name_en)="data">
          <b-link class="cursor-pointer text-primary">
            <!-- @click="showEditSportTeamModal(data.item)" -->
            {{ data.item.name_en }}
          </b-link>
        </template>
        <template #cell(sport_type)="data">
          <div v-if="data.item.sport_type">
            {{ data.item.sport_type.name }}
          </div>
        </template>

        <template #cell(logo)="data">
          <b-link
            :href="baseUrl + data.item.logo"
            target="_blank"
            class="cursor-pointer"
            v-if="data.item.logo != null"
          >
            <b-img :src="baseUrl + data.item.logo" fluid class="mr-2 rounded" style="width: 100px; height: 100px;"/>
          </b-link>
          <b-link v-else> </b-link>
        </template>

        <template #cell(created_by)="data">
          {{ data.item.created_by }}
        </template>
        <template #cell(updated_by)="data">
          {{ data.item.updated_by }}
        </template>
        <template #cell(created_at)="data">
          <div v-if="data.item.created_at !== null">
            {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
          </div>
          <div v-else></div>
        </template>

        <template #cell(updated_at)="data">
          <div v-if="data.item.updated_at !== null">
            {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
          </div>
          <div v-else></div>
        </template>

        <template #cell(action)="data">
          <b-link
            v-if="$can('update', 'sportteam')"
            class="font-weight-bold text-nowrap"
            @click="showEditSportTeamModal(data.item)"
          >
            Edit
          </b-link>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSportTeam"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Modal: Add sports Group -->
    <add-sports-team-modal
      :sport-type-id.sync="sportTypeId"
      @refetch-data="refetchData"
    />

    <!-- Modal: Edit sports Group -->
    <update-sport-team-modal
      :sport-type-id.sync="sportTypeId"
      :sport-team-detail.sync="sportTeamDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import sportsStoreModule from "@/views/sports/sportsStoreModule";
import AddSportsTeamModal from "@/views/sports/AddSportsTeam.vue";
import UpdateSportTeamModal from "@/views/sports/UpdateSportsTeam.vue";

export default {
  components: {
    // AddsportTeamModal,
    // EditsportTeamModal,
    AddSportsTeamModal,
    UpdateSportTeamModal,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      sportTeamDetail: null,
    };
  },
  methods: {
    showAddSportTeamModal() {
      this.$bvModal.show("modal-add-sports-team");
    },
    showEditSportTeamModal(data) {
      this.sportTeamDetail = data;
      this.$bvModal.show("modal-edit-sports-team");
    },
  },
  setup() {
    if (!store.hasModule("sports"))
      store.registerModule("sports", sportsStoreModule);
    onUnmounted(() => {
      if (store.hasModule("sports")) store.unregisterModule("sports");
    });

    const toast = useToast();

    const search = ref({
      name: "",
      sport_type_id: "",
    });
    const sportTypeId = ref([]);
    const sportTypeIdFilter = ref(null);
    const nameFilter = ref(null);

    const perPage = ref(25);
    const totalSportTeam = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);

    const refSportTeamListTable = ref(null);

    const tableColumns = [
      { key: "index", label: "#" },
      "name",
      // "name_en",

      "logo",
      "sport_type",
      "created_by",
      "updated_by",
      "created_at",
      "updated_at",
      "action",
    ];

    const refetchData = () => {
      refSportTeamListTable.value.refresh();
    };

    watch([currentPage, nameFilter, sportTypeIdFilter], () => {
      refetchData();
    });

    const sportTeamLists = (ctx, callback) => {
      store
        .dispatch("sports/fetchSportTeam", {
          name: nameFilter.value,
          sport_type_id: sportTypeIdFilter.value,
          page: currentPage.value,
        })
        .then((response) => {
          //   const { sportTeams, total, pageSize } = response;
          callback(response.sportTeam);
          totalSportTeam.value = response.total;
          perPage.value = response.pageSize;
        });
    };
    store.dispatch("sports/fetchSportType",{}).then((response) => {
      sportTypeId.value = response.sportType;
    });

    const dataMeta = computed(() => {
      const localItemsCount = refSportTeamListTable.value
        ? refSportTeamListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalSportTeam.value,
      };
    });

    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "inActive", value: 2 },
    ];

    const resolveStatus = (status) => {
      if (status == 1) return { label: "Active", variant: "primary" };
      if (status == 2) return { label: "inActive", variant: "danger" };
      return { label: "Pending", variant: "secondary" };
    };

    const resetFilter = () => {
      search.value.name = "";
      search.value.sport_type_id = "";
      nameFilter.value = null;
      sportTypeIdFilter.value = null;
    };

    const searchFilter = () => {
      nameFilter.value = search.value.name;
      sportTypeIdFilter.value = search.value.sport_type_id;
    };

    return {
      tableColumns,
      perPage,
      currentPage,
      totalSportTeam,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refSportTeamListTable,

      sportTeamLists,
      refetchData,
      nameFilter,
      search,

      resetFilter,
      sportTypeIdFilter,

      resolveStatus,
      statusOptions,

      formatDateTime,
      searchFilter,
      baseUrl,
      sportTypeId,
    };
  },
};
</script>
